import React from 'react';

const AboutMe = () => {
  return (
    <div className="p-4 bg-base-200 text-base-content min-h-screen">
      <h1 className="text-4xl font-bold text-center sm:text-left">Über mich</h1>
      <p className="mt-2 text-center sm:text-left">Hallo! Ich bin ein leidenschaftlicher Entwickler, der es liebt, kreative Lösungen zu finden und Spaß an der Arbeit zu haben.</p>
      
      <h2 className="text-2xl font-bold mt-4 text-center sm:text-left">Meine Hobbys</h2>
      <ul className="list-disc list-inside mt-2 space-y-2">
        <li className="text-lg sm:text-xl">Programmieren</li>
        <li className="text-lg sm:text-xl">Memes erstellen</li>
        <li className="text-lg sm:text-xl">Videospiele spielen</li>
        <li className="text-lg sm:text-xl">Mit Freunden abhängen</li>
      </ul>

      <h2 className="text-2xl font-bold mt-4 text-center sm:text-left">Lustige Fakten</h2>
      <ul className="list-disc list-inside mt-2 space-y-2">
        <li className="text-lg sm:text-xl">Ich habe einmal versucht, meinen Computer mit einer Banane zu steuern. Spoiler: Es hat nicht funktioniert.</li>
        <li className="text-lg sm:text-xl">Ich habe mehr Memes auf meinem Computer als Fotos von mir selbst.</li>
        <li className="text-lg sm:text-xl">Ich kann den gesamten Text von "Rick Astley - Never Gonna Give You Up" auswendig.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-4 text-center sm:text-left">Ein lustiges Meme</h2>
      <div className="flex justify-center sm:justify-start">
        <img src="https://i.imgflip.com/4/30b1gx.jpg" alt="Lustiges Meme" className="mt-2 rounded-lg shadow-lg" />
      </div>
    </div>
  );
};

export default AboutMe;