import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import MyTools from './pages/MyTools';
import QRCodeGenerator from './pages/QRCodeGenerator';
import PasswordGenerator from './pages/PasswordGenerator';
import GeoIP from './pages/GeoIP';
import Converter from './pages/Converter';
import MinecraftServerStats from './pages/MinecraftServerStats';
import CoinFlip from './pages/CoinFlip';
import PageTransition from './components/PageTransition';

function App() {
  const location = useLocation();

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<PageTransition><Home /></PageTransition>} />
            <Route path="/about-me" element={<PageTransition><AboutMe /></PageTransition>} />
            <Route path="/my-tools" element={<PageTransition><MyTools /></PageTransition>} />
            <Route path="/tools/qrcode-generator" element={<PageTransition><QRCodeGenerator /></PageTransition>} />
            <Route path="/tools/password-generator" element={<PageTransition><PasswordGenerator /></PageTransition>} />
            <Route path="/tools/geoip" element={<PageTransition><GeoIP /></PageTransition>} />
            <Route path="/tools/converter" element={<PageTransition><Converter /></PageTransition>} />
            <Route path="/tools/minecraft-server-stats" element={<PageTransition><MinecraftServerStats /></PageTransition>} />
            <Route path="/tools/coin-flip" element={<PageTransition><CoinFlip /></PageTransition>} />
          </Routes>
        </AnimatePresence>
      </div>
    </>
  );
}

export default App;