import React from 'react';

const tools = [
  {
    name: 'JetBrains IntelliJ IDEA',
    logo: 'https://resources.jetbrains.com/storage/products/company/brand/logos/IntelliJ_IDEA_icon.svg',
    link: 'https://www.jetbrains.com/idea/',
    description: 'Eine leistungsstarke IDE für Java-Entwicklung und viele andere Programmiersprachen.'
  },
  {
    name: 'JetBrains DataGrip',
    logo: 'https://resources.jetbrains.com/storage/products/company/brand/logos/DataGrip_icon.svg',
    link: 'https://www.jetbrains.com/datagrip/',
    description: 'Ein intelligentes SQL-Editor-Tool für Datenbankentwickler.'
  },
  {
    name: 'Visual Studio Code',
    logo: 'https://code.visualstudio.com/assets/images/code-stable.png',
    link: 'https://code.visualstudio.com/',
    description: 'Ein kostenloser Quellcode-Editor mit Unterstützung für viele Programmiersprachen und Erweiterungen.'
  },
  {
    name: 'GitHub Copilot',
    logo: 'https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png',
    link: 'https://github.com/features/copilot',
    description: 'Ein KI-gestützter Code-Assistent, der Entwicklern hilft, schneller und effizienter zu programmieren.'
  },
  {
    name: 'ChatGPT',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/0/04/ChatGPT_logo.svg',
    link: 'https://chat.openai.com/',
    description: 'Ein KI-Chatbot, der natürliche Sprache versteht und Antworten auf eine Vielzahl von Fragen geben kann.'
  }
];

const MyTools = () => {
  return (
    <div className="p-4 bg-base-300 text-base-content">
      <h1 className="text-4xl font-bold text-center sm:text-left">Das nutze ich</h1>
      <div className="flex flex-wrap justify-center gap-4 mt-4">
        {tools.map((tool, index) => (
          <div key={index} className="card bg-base-100 shadow-xl w-60">
            <figure className="px-10 pt-10">
              <img src={tool.logo} alt={`${tool.name} Logo`} className="rounded-xl w-16 h-16" />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">
                <a href={tool.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {tool.name}
                </a>
              </h2>
              <p className="text-sm">{tool.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyTools;