import React from 'react';
import { motion } from 'framer-motion';

const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      style={{ width: '100%', height: '100%', paddingTop: '64px' }} // Adjust paddingTop to match the height of the Navbar
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;