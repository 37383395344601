import React, { useState } from 'react';

const conversions = {
  'GB to MB': (value) => value * 1024,
  'MB to GB': (value) => value / 1024,
  'GB to MiB': (value) => value * 1024 * 1024 / (1024 * 1024),
  'MiB to GB': (value) => value / 1024,
  'MB to MiB': (value) => value / 1.048576,
  'MiB to MB': (value) => value * 1.048576,
};

const Converter = () => {
  const [inputValue, setInputValue] = useState('');
  const [conversionType, setConversionType] = useState('GB to MB');
  const [result, setResult] = useState(null);

  const handleConvert = () => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      const convertedValue = conversions[conversionType](value);
      setResult(convertedValue);
    } else {
      setResult('Invalid input');
    }
  };

  return (
    <div className="p-4 bg-base-300 text-base-content min-h-screen flex flex-col items-center">
      <h1 className="text-4xl font-bold mb-6">GB2MB Rechner</h1>
      <div className="mb-4">
        <label className="mr-2">Wert:</label>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="input input-bordered"
        />
      </div>
      <div className="mb-4">
        <label className="mr-2">Umrechnungstyp:</label>
        <select
          value={conversionType}
          onChange={(e) => setConversionType(e.target.value)}
          className="select select-bordered"
        >
          <option value="GB to MB">GB to MB</option>
          <option value="MB to GB">MB to GB</option>
          <option value="GB to MiB">GB to MiB</option>
          <option value="MiB to GB">MiB to GB</option>
          <option value="MB to MiB">MB to MiB</option>
          <option value="MiB to MB">MiB to MB</option>
        </select>
      </div>
      <button className="btn btn-primary mb-4" onClick={handleConvert}>
        Umrechnen
      </button>
      {result !== null && (
        <div className="mt-4 p-4 border rounded bg-base-100">
          <strong>Ergebnis:</strong> {result}
        </div>
      )}
    </div>
  );
};

export default Converter;