import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className="navbar bg-base-100 text-base-content shadow-lg fixed top-0 left-0 w-full z-50">
      <div className="flex-1">
        <Link to="/" className="btn btn-ghost normal-case text-xl">wildesPepega</Link>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          <li><Link to="/about-me" className="btn btn-ghost">Über mich</Link></li>
          <li><Link to="/my-tools" className="btn btn-ghost">Das nutze ich</Link></li>
          <li className="dropdown dropdown-hover dropdown-left">
            <label tabIndex={0} className="btn btn-ghost">
              Nützliche Tools
            </label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 mt-2">
              <li><Link to="/tools/qrcode-generator">QR-Code Generator</Link></li>
              <li><Link to="/tools/password-generator">Passwortgenerator</Link></li>
              <li><Link to="/tools/geoip">GeoIP</Link></li>
              <li><Link to="/tools/converter">GB2MB Rechner</Link></li>
              <li><Link to="/tools/minecraft-server-stats">Minecraft Server Stats</Link></li>
              <li><Link to="/tools/coin-flip">Münzwurf</Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;