// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coin {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: gold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .coin.flipping {
    animation: flip 1s infinite;
  }
  
  @keyframes flip {
    0% { transform: rotateY(0); }
    50% { transform: rotateY(180deg); }
    100% { transform: rotateY(360deg); }
  }
  
  .coin-face {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/pages/CoinFlip.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,KAAK,qBAAqB,EAAE;IAC5B,MAAM,0BAA0B,EAAE;IAClC,OAAO,0BAA0B,EAAE;EACrC;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;EACd","sourcesContent":[".coin {\r\n    width: 100px;\r\n    height: 100px;\r\n    border-radius: 50%;\r\n    background-color: gold;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .coin.flipping {\r\n    animation: flip 1s infinite;\r\n  }\r\n  \r\n  @keyframes flip {\r\n    0% { transform: rotateY(0); }\r\n    50% { transform: rotateY(180deg); }\r\n    100% { transform: rotateY(360deg); }\r\n  }\r\n  \r\n  .coin-face {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n    height: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
