import React from 'react';

const Home = () => {
  return (
    <div className="p-4 bg-base-200 text-base-content min-h-screen">
      <h1 className="text-4xl font-bold text-center sm:text-left">Willkommen auf meiner Webseite!</h1>
      <p className="mt-2 text-center sm:text-left">Dies ist meine persönliche Webseite, auf der du mehr über mich und meine Projekte erfahren kannst.</p>
      
      <h2 className="text-2xl font-bold mt-4 text-center sm:text-left">Lustige Elemente</h2>
      <p className="mt-2 text-center sm:text-left">Hier sind einige lustige Elemente, um dir ein Lächeln ins Gesicht zu zaubern:</p>
      
      <h3 className="text-xl font-bold mt-4 text-center sm:text-left">Ein lustiges Meme</h3>
      <div className="flex justify-center sm:justify-start">
        <img src="https://i.imgflip.com/4/30b1gx.jpg" alt="Lustiges Meme" className="mt-2 rounded-lg shadow-lg" />
      </div>
      
      <h3 className="text-xl font-bold mt-4 text-center sm:text-left">Ein lustiges Video</h3>
      <div className="flex justify-center sm:justify-start">
        <iframe className="mt-2 rounded-lg shadow-lg" width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default Home;