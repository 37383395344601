import React, { useState } from 'react';
import './CoinFlip.css'; // Importiere die CSS-Datei für die Animation

const CoinFlip = () => {
  const [result, setResult] = useState(null);
  const [flipping, setFlipping] = useState(false);

  const handleFlip = () => {
    setFlipping(true);
    setTimeout(() => {
      const flipResult = Math.random() < 0.5 ? 'Kopf' : 'Zahl';
      setResult(flipResult);
      setFlipping(false);
    }, 1000); // Dauer der Animation
  };

  return (
    <div className="p-4 bg-base-300 text-base-content min-h-screen flex flex-col items-center">
      <h1 className="text-4xl font-bold mb-6">Münzwurf</h1>
      <div className={`coin ${flipping ? 'flipping' : ''}`}>
        {result && <div className="coin-face">{result}</div>}
      </div>
      <button className="btn btn-primary mt-4" onClick={handleFlip} disabled={flipping}>
        Münze werfen
      </button>
    </div>
  );
};

export default CoinFlip;