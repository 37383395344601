import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default icon issue with Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const GeoIP = () => {
  const [ipInfo, setIpInfo] = useState(null);
  const [input, setInput] = useState('');

  const fetchIPInfo = async (inputValue) => {
    try {
      const response = await axios.get(`https://ipapi.co/${inputValue}/json/`);
      setIpInfo(response.data);
    } catch (error) {
      console.error('Error fetching IP info:', error);
    }
  };

  useEffect(() => {
    const fetchOwnIP = async () => {
      try {
        const response = await axios.get('https://api64.ipify.org?format=json');
        setInput(response.data.ip);
        fetchIPInfo(response.data.ip);
      } catch (error) {
        console.error('Error fetching own IP:', error);
      }
    };

    fetchOwnIP();
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSearch = () => {
    fetchIPInfo(input);
  };

  return (
    <div className="p-4 bg-base-300 text-base-content min-h-screen">
      <h1 className="text-4xl font-bold text-center sm:text-left mb-6">GeoIP Informationen</h1>
      <div className="card bg-base-100 shadow-xl w-full max-w-2xl mx-auto p-6">
        <div className="mb-4">
          <input
            type="text"
            className="input input-bordered w-full"
            value={input}
            onChange={handleInputChange}
            placeholder="IP-Adresse oder Domain eingeben"
          />
          <button className="btn btn-primary mt-2" onClick={handleSearch}>Suchen</button>
        </div>
        {ipInfo ? (
          <>
            <p><strong>IP-Adresse (IPv4):</strong> {ipInfo.ip}</p>
            <p><strong>Stadt:</strong> {ipInfo.city}</p>
            <p><strong>Region:</strong> {ipInfo.region}</p>
            <p><strong>Land:</strong> {ipInfo.country_name}</p>
            <p><strong>Postleitzahl:</strong> {ipInfo.postal}</p>
            <p><strong>Internet Anbieter:</strong> {ipInfo.org}</p>
            {ipInfo.latitude && ipInfo.longitude ? (
              <MapContainer center={[ipInfo.latitude, ipInfo.longitude]} zoom={13} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[ipInfo.latitude, ipInfo.longitude]}>
                  <Popup>
                    {ipInfo.city}, {ipInfo.region}, {ipInfo.country_name}
                  </Popup>
                </Marker>
              </MapContainer>
            ) : (
              <p>Keine Standortinformationen verfügbar.</p>
            )}
          </>
        ) : (
          <p>Lade IP-Informationen...</p>
        )}
      </div>
    </div>
  );
};

export default GeoIP;