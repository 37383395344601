import React, { useState } from 'react';
import axios from 'axios';

const MinecraftServerStats = () => {
  const [serverAddress, setServerAddress] = useState('');
  const [serverStats, setServerStats] = useState(null);
  const [error, setError] = useState(null);

  const handleFetchStats = async () => {
    try {
      const response = await axios.get(`https://api.mcsrvstat.us/2/${serverAddress}`);
      if (response.data.online) {
        setServerStats(response.data);
        setError(null);
      } else {
        setError('Server nicht gefunden. Bitte überprüfen Sie die IP/Domain.');
        setServerStats(null);
      }
    } catch (err) {
      setError('Fehler beim Abrufen der Serverstatistiken. Bitte überprüfen Sie die IP/Domain.');
      setServerStats(null);
    }
  };

  return (
    <div className="p-4 bg-base-300 text-base-content min-h-screen flex flex-col items-center">
      <h1 className="text-4xl font-bold mb-6">Minecraft Server Stats Viewer</h1>
      <div className="mb-4">
        <label className="mr-2">IP/Domain:</label>
        <input
          type="text"
          value={serverAddress}
          onChange={(e) => setServerAddress(e.target.value)}
          className="input input-bordered"
        />
      </div>
      <button className="btn btn-primary mb-4" onClick={handleFetchStats}>
        Statistiken abrufen
      </button>
      {error && (
        <div className="mt-4 p-4 border rounded bg-red-100 text-red-700">
          <strong>Fehler:</strong> {error}
        </div>
      )}
      {serverStats && (
        <div className="mt-4 p-4 border rounded bg-base-100">
          <p><strong>MOTD:</strong> {serverStats.motd.clean}</p>
          <p><strong>Spieler:</strong> {serverStats.players.online} / {serverStats.players.max}</p>
          <p><strong>Version:</strong> {serverStats.version}</p>
          <p><strong>IP:</strong> {serverStats.ip}</p>
          <p><strong>Port:</strong> {serverStats.port}</p>
          {serverStats.software && <p><strong>Serversoftware:</strong> {serverStats.software}</p>}
        </div>
      )}
    </div>
  );
};

export default MinecraftServerStats;