import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeGenerator = () => {
  const [text, setText] = useState('');
  const [size, setSize] = useState(128);
  const qrRef = useRef();

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qrcode.png';
    link.click();
  };

  return (
    <div className="p-4 bg-base-300 text-base-content min-h-screen">
      <h1 className="text-4xl font-bold text-center sm:text-left mb-6">QR-Code Generator</h1>
      <div className="card bg-base-100 shadow-xl w-full max-w-2xl mx-auto p-6">
        <input
          type="text"
          placeholder="Text eingeben"
          className="input input-bordered w-full mb-4"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <input
          type="number"
          placeholder="Größe (px)"
          className="input input-bordered w-full mb-4"
          value={size}
          onChange={(e) => setSize(Number(e.target.value))}
        />
        <div className="flex justify-center" ref={qrRef}>
          <QRCodeCanvas
            value={text}
            size={size}
          />
        </div>
        <button className="btn btn-primary mt-4" onClick={handleDownload}>
          QR-Code herunterladen
        </button>
      </div>
    </div>
  );
};

export default QRCodeGenerator;