import React, { useState } from 'react';

const PasswordGenerator = () => {
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(12);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSymbols, setIncludeSymbols] = useState(true);
  const [copied, setCopied] = useState(false);

  const generatePassword = () => {
    let chars = '';
    if (includeLowercase) chars += 'abcdefghijklmnopqrstuvwxyz';
    if (includeUppercase) chars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (includeNumbers) chars += '0123456789';
    if (includeSymbols) chars += '!@#$%^&*()_+';

    let pass = '';
    for (let i = 0; i < length; i++) {
      pass += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setPassword(pass);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="p-4 bg-base-300 text-base-content min-h-screen">
      <h1 className="text-4xl font-bold text-center sm:text-left mb-6">Passwortgenerator</h1>
      <div className="card bg-base-100 shadow-xl w-full max-w-2xl mx-auto p-6">
        <div className="mb-4">
          <label className="label">
            <span className="label-text">Länge: {length}</span>
          </label>
          <input
            type="range"
            min="4"
            max="128"
            value={length}
            onChange={(e) => setLength(Number(e.target.value))}
            className="range range-primary"
          />
        </div>
        <div className="mb-4">
          <label className="cursor-pointer label">
            <span className="label-text">Kleinbuchstaben</span>
            <input
              type="checkbox"
              checked={includeLowercase}
              onChange={(e) => setIncludeLowercase(e.target.checked)}
              className="checkbox checkbox-primary"
            />
          </label>
          <label className="cursor-pointer label">
            <span className="label-text">Großbuchstaben</span>
            <input
              type="checkbox"
              checked={includeUppercase}
              onChange={(e) => setIncludeUppercase(e.target.checked)}
              className="checkbox checkbox-primary"
            />
          </label>
          <label className="cursor-pointer label">
            <span className="label-text">Zahlen</span>
            <input
              type="checkbox"
              checked={includeNumbers}
              onChange={(e) => setIncludeNumbers(e.target.checked)}
              className="checkbox checkbox-primary"
            />
          </label>
          <label className="cursor-pointer label">
            <span className="label-text">Sonderzeichen</span>
            <input
              type="checkbox"
              checked={includeSymbols}
              onChange={(e) => setIncludeSymbols(e.target.checked)}
              className="checkbox checkbox-primary"
            />
          </label>
        </div>
        <button className="btn btn-primary mb-4" onClick={generatePassword}>Passwort generieren</button>
        <div className="flex items-center mb-4">
          <input
            type="text"
            readOnly
            className="input input-bordered w-full"
            value={password}
          />
          <button className="btn btn-secondary ml-2 relative" onClick={copyToClipboard}>
            Kopieren
            {copied && (
              <span className="absolute top-0 right-0 mt-2 mr-2 text-green-500 transition-opacity duration-500 ease-in-out opacity-100">
                Kopiert!
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordGenerator;